/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import './src/styles/fonts.css'
import './src/styles/tailwind.css'
import './src/styles/theme.css'

import React from 'react'
import { StaticComponent } from './src/layouts/Static'
// import * as Sentry from '@sentry/browser'
// const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development'

// if (activeEnv === 'production') {
//   Sentry.init({
//     dsn: 'https://f989f9fe4b314186aee68e3b48df745b@sentry.finksmart.de/5',
//     // We recommend adjusting this value in production, or using tracesSampler
//     // for finer control
//     tracesSampleRate: 1.0,
//   })
// }

// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
  return <StaticComponent {...props}>{element}</StaticComponent>
}
