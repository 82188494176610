import * as React from 'react'
import { useDispatch } from 'react-redux'
import { useShopParagraphSlice } from '../../components/Paragraphs/ShopParagraph/slice'

export const StaticComponent = ({ children }) => {
  // Actions.
  const dispatch = useDispatch()
  const { actions } = useShopParagraphSlice()
  const onInitShop = React.useCallback(
    () => dispatch(actions.init()),
    [actions, dispatch]
  )

  // Hooks.
  React.useEffect(() => {
    onInitShop()
  }, [onInitShop])

  return children
}
