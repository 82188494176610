import { call, put, select, takeLatest, takeEvery } from 'redux-saga/effects'
import Client from 'shopify-buy'
import { shopParagraphActions } from '.'
import { selectCheckoutId } from './selectors'
import { loadingComponentActions } from '../../../LoadingComponent/slice'

// Initializing a client to return content in the store's primary language
const client = Client.buildClient({
  domain: 'evoped.myshopify.com',
  storefrontAccessToken: 'b01f192da2602b40ecf7d5a9f4f08efc'
})

const createCheckout = () => {
  return new Promise((resolve, reject) => {
    client.checkout
      .create()
      .then((checkout) => {
        resolve(checkout)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

const addToCheckout = (variantId: string, checkoutId: string) => {
  const lineItemsToAdd = [{ variantId, quantity: 1 }]
  return new Promise((resolve, reject) => {
    client.checkout
      .addLineItems(checkoutId, lineItemsToAdd)
      .then((checkout) => {
        resolve(checkout)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

const updateCheckout = (checkoutId, lineItemId, quantity) => {
  const lineItemsToUpdate = [{ id: lineItemId, quantity: parseInt(quantity) }]
  return new Promise((resolve, reject) => {
    client.checkout
      .updateLineItems(checkoutId, lineItemsToUpdate)
      .then((checkout) => {
        resolve(checkout)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

const removeFromCheckout = (checkoutId: string, lineItemId: string) => {
  const lineItemIdsToRemove = [lineItemId]
  return new Promise((resolve, reject) => {
    client.checkout
      .removeLineItems(checkoutId, lineItemIdsToRemove)
      .then((checkout) => {
        resolve(checkout)
      })
      .catch((err) => {
        console.log(err)
        reject(err)
      })
  })
}

const fetchCheckout = (checkoutId: string) => {
  return new Promise((resolve, reject) => {
    client.checkout
      .fetch(checkoutId)
      .then((checkout) => {
        resolve(checkout)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

const openCheckOutUrl = (url: string) => {
  if (typeof window !== 'undefined') {
    window.location.href = url
  }
}

function* initSaga() {
  if (sessionStorage) {
    const checkoutId = sessionStorage.getItem('checkoutId')

    let checkout
    if (checkoutId) {
      checkout = yield call(fetchCheckout, checkoutId)
    } else {
      checkout = yield call(createCheckout)
    }

    sessionStorage.setItem('checkoutId', checkout.attrs.id.value)

    yield put(shopParagraphActions.setTotalPrice(checkout.totalPriceV2?.amount))
    yield put(
      shopParagraphActions.setSubtotalPrice(checkout.subtotalPriceV2?.amount)
    )

    yield put(shopParagraphActions.setCheckoutId(checkout.attrs.id.value))
    yield put(shopParagraphActions.setLineItems(checkout.lineItems))
  }
}

function* addLineItemSaga(action) {
  yield put(loadingComponentActions.setIsLoading(true))

  const { payload } = action

  const checkoutId = yield select(selectCheckoutId)

  // Add the line item id to the product you added.
  const checkout = yield call(addToCheckout, payload, checkoutId)
  yield put(shopParagraphActions.setLineItems(checkout.lineItems))
  yield put(shopParagraphActions.setTotalPrice(checkout.totalPriceV2.amount))
  yield put(
    shopParagraphActions.setSubtotalPrice(checkout.subtotalPriceV2.amount)
  )

  yield put(loadingComponentActions.setIsLoading(false))
}

function* removeLineItemSaga(action) {
  yield put(loadingComponentActions.setIsLoading(true))
  try {
    const { payload } = action
    const checkoutId = yield select(selectCheckoutId)
    const checkout = yield call(removeFromCheckout, checkoutId, payload)
    yield put(shopParagraphActions.setLineItems(checkout.lineItems))
    yield put(shopParagraphActions.setTotalPrice(checkout.totalPriceV2.amount))
    yield put(
      shopParagraphActions.setSubtotalPrice(checkout.subtotalPriceV2.amount)
    )
  } catch (err: any) {
    console.log(err)
  } finally {
    yield put(loadingComponentActions.setIsLoading(false))
  }
}

function* redirectToCheckoutSaga() {
  const checkoutId = yield select(selectCheckoutId)
  const checkout = yield call(fetchCheckout, checkoutId)
  yield call(openCheckOutUrl, checkout.webUrl)
}

function* changeQuantitySaga(action) {
  yield put(loadingComponentActions.setIsLoading(true))
  const { payload } = action
  try {
    const checkoutId = yield select(selectCheckoutId)
    const checkout = yield call(
      updateCheckout,
      checkoutId,
      payload.lineItemId,
      payload.quantity
    )
    yield put(shopParagraphActions.setLineItems(checkout.lineItems))
    yield put(shopParagraphActions.setTotalPrice(checkout.totalPriceV2.amount))
    yield put(
      shopParagraphActions.setSubtotalPrice(checkout.subtotalPriceV2.amount)
    )
  } catch (e: any) {
    console.log(e)
  } finally {
    yield put(loadingComponentActions.setIsLoading(false))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* shopParagraphSaga() {
  // Watches for loadRepos actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(shopParagraphActions.init.type, initSaga)
  yield takeEvery(shopParagraphActions.addLineItem.type, addLineItemSaga)
  yield takeLatest(shopParagraphActions.changeQuantity.type, changeQuantitySaga)
  yield takeLatest(
    shopParagraphActions.redirectToCheckout.type,
    redirectToCheckoutSaga
  )
  yield takeLatest(shopParagraphActions.removeLineItem.type, removeLineItemSaga)
}
