import { createSelector } from '@reduxjs/toolkit'

import { initialState } from '.'
import { RootState } from '../../../../types'

const selectDomain = (state: RootState) =>
  typeof state !== 'undefined'
    ? state.shopParagraph || initialState
    : initialState

export const selectCheckoutId = createSelector(
  [selectDomain],
  (state) => state.checkoutId
)

export const selectLineItems = createSelector(
  [selectDomain],
  (state) => state.lineItems
)

export const selectTotalPrice = createSelector(
  [selectDomain],
  (state) => state.totalPrice
)

export const selectSubtotalPrice = createSelector(
  [selectDomain],
  (state) => state.subtotalPrice
)
