import { createSlice } from '../../../utils/@reduxjs/toolkit'
import { useInjectReducer } from '../../../utils/redux-injectors'
import { ILoadingComponent } from './types'

export const initialState: ILoadingComponent = {
  isLoading: false
}

const slice = createSlice({
  name: 'loadingComponent',
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload
    }
  }
})

export const { actions: loadingComponentActions, reducer } = slice

export const useLoadingComponentSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer })
  return { actions: slice.actions }
}
