import { createSlice } from '../../../../utils/@reduxjs/toolkit'
import { useInjectReducer } from '../../../../utils/redux-injectors'
import { IShopParagraph } from './types'
import { useInjectSaga } from 'redux-injectors'
import { shopParagraphSaga } from './saga'

export const initialState: IShopParagraph = {
  lineItems: [],
  totalPrice: '0.0',
  subtotalPrice: '0.0',
  checkoutId: null
}

const slice = createSlice({
  name: 'shopParagraph',
  initialState,
  reducers: {
    init() {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    addLineItem(state, action: { payload: string }) {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    removeLineItem(state, action) {},
    setLineItems(state, { payload }) {
      state.lineItems = payload
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    changeQuantity(state, action) {},
    redirectToCheckout() {},
    setCheckoutId(state, action) {
      state.checkoutId = action.payload
    },

    setTotalPrice(state, action) {
      state.totalPrice = action.payload
    },
    setSubtotalPrice(state, action) {
      state.subtotalPrice = action.payload
    }
  }
})

export const { actions: shopParagraphActions, reducer } = slice

export const useShopParagraphSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer })
  useInjectSaga({ key: slice.name, saga: shopParagraphSaga })
  return { actions: slice.actions }
}
