import { RootStateKeyType } from '../types/injector-typings'
import {
  createSlice as createSliceOriginal,
  SliceCaseReducers,
  CreateSliceOptions
} from '@reduxjs/toolkit'

/* Wrap createSlice with stricter Name options */

/* istanbul ignore next */
export const createSlice = <
  State,
  CaseReducers extends SliceCaseReducers<State>,
  Name extends RootStateKeyType
>(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  options: CreateSliceOptions<State, CaseReducers, Name>
) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return createSliceOriginal(options)
}
